
$('.js-open-modal').click(function (e) {
    e.preventDefault();
    const w = $(window).outerWidth();
    const popup = $(this).data('popup');
    $('.js-nav').removeClass('is-open');
    if (w < 443) {
        $('.popup').removeClass('is-visible');
        $(`.${popup}`).addClass('is-visible');
        return;
    }

    $(`.${popup}`).fadeIn(600);
    $('html').css({ overflow: 'hidden' });
});

$('.js-close-modal').click((e) => {
    e.preventDefault();
    const w = $(window).outerWidth();
    const popup = e.target.closest('.popup');
    if (w < 443) {
        $(popup).removeClass('is-visible');
        return;
    }
    $(popup).fadeOut(600);
    $('html').css({ overflow: 'visible', 'overflowX': 'hidden' });
});
document.addEventListener('touchmove', (event) => {
    if ($('.popup').hasClass('is-visible')) {
        event.preventDefault();
    }
}, { passive: false });


$('.js-hide-greet').click((e) => {
    e.preventDefault();
    const popup = e.target.closest('.popup');
    $(popup).fadeOut(600);
});

$('.nav-side__btn').click(function (e) {
    e.preventDefault();
    $('.nav-side__btn').removeClass('is-active');
    $(this).addClass('is-active');
    if ($(this).hasClass('tile')) {
        $('.time-line').hide();
        $('.cards').fadeIn(400);
    }
    if ($(this).hasClass('list')) {
        $('.cards').hide();
        $('.time-line').fadeIn(400);
    }
    $('.time-line').toggleClass('is-blocked');
    $('.cards').toggleClass('is-blocked');
});

$(window).scroll(function (e) {
    $('.nav-side').removeClass('is-active');

    if ($(window).scrollTop() > $(window).outerHeight()) {
        clearTimeout($.data(this, 'scrollTimer'));
        $.data(this, 'scrollTimer', setTimeout(() => {
            $('.nav-side').removeClass('hide-icon');
            $('.nav-side').addClass('is-active');
        }, 250));
    } else {
        $('.nav-side').addClass('hide-icon');
    }
});


$('.nav-side__icon').click(() => {
    // $('.nav-side').removeClass('is-active');
    $('.nav-side').toggleClass('is-blocked');
});

// $(window).scroll(() => {
//     console.log('jd');
// });
// $(window).scroll(() => {
//     const h = $(window).outerHeight();
//     //     // if ($(window).scrollTop() > h / 0.5) {
//     //     //     console.log('done');
//     //     //     $('.trigger').hide();
//     //     //     $('.content').removeClass('is-fixed');
//     //     // }
//     //     // console.log($(window).scrollTop());
//     //     const sc = $(window).scrollTop() / 3;
//     const sco = $(window).scrollTop();

//     if (sco < h / 3 && sco > '30') {
//         $('.content').css({
//             transform: `translate3d(0,${sco}px,0)`,
//             '-webkit-transform': `translate3d(0,${sco}px,0)`,
//             '-ms-transform': `translate3d(0,${sco}px,0)`,
//             '-o-transform': `translate3d(0,${sco}px,0)`,

//         });
//         $('.greet').fadeOut(600);
//     } else if (sco >= h / 3) {
//         $('.greet').fadeOut(600);
//     } else {
//         $('.greet').fadeIn(600);
//         $('.content').css({
//             transform: `translate3d(0,${sco}px,0)`,
//             '-webkit-transform': `translate3d(0,${sco}px,0)`,
//             '-ms-transform': `translate3d(0,${sco}px,0)`,
//             '-o-transform': `translate3d(0,${sco}px,0)`,

//         });
//     }
// });
const animateGreetScreen = () => {
    // const h = $(window).outerHeight();
    const tl = new TimelineMax({ paused: true });
    const tl2 = new TimelineMax();
    const controller = new ScrollMagic.Controller();

    // if (!($('.js-hide-greet').hasClass('is-animated'))) {
    //     alert(';');

    tl
        .to('.greet ', 1.2, {
            x: '0%',
            autoAlpha: 0,
            ease: Power0.easeNone,
        })
        .to('.hero .subtitle', 1.5, {
            y: '0',
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, '-=1')
        .to('.hero .title', 1.5, {
            y: '0',
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, '-=1.7');


    // const tt = new ScrollMagic.Scene({
   const scene = new ScrollMagic.Scene({
        triggerElement: '.trigger',
        // triggerElement: '.greet-scroll',
        // triggerElement: '.greet',
        triggerHook: 0,
        offset: '100',
        duration: '100%',
    })
        .setTween(tl.play())
        .setPin('.hero')
        // .addIndicators()
        .addTo(controller);
    // tt.on('end', (event) => {
    //     console.log('Hit end point of scene.');
    // });
    $('.js-hide-greet').click((e) => {
        e.preventDefault();
        // $('.trigger').hide();
        // $('.content').removeClass('is-fixed');
        scene.destroy(true);
        tl2
            .to('.greet ', 1.2, {
                x: '0%',
                autoAlpha: 0,
                ease: Power0.easeNone,
            })
            .to('.greet ',0.1, {
                zIndex: '-9'
            })
            .to('.hero .subtitle', 1.5, {
                y: '0',
                autoAlpha: 1,
                ease: Power0.easeNone,
            }, '-=1')
            .to('.hero .title', 1.5, {
                y: '0',
                autoAlpha: 1,
                ease: Power0.easeNone,
            }, '-=1.7');
        $('.trigger').remove();
    });
};
animateGreetScreen();

const animateCircle = () => {
    const controller = new ScrollMagic.Controller();
    $('.time-line__circle').each(function () {
        const tlScroll = new TimelineLite();
        const circle = $(this).find('.circle-mask');
        const elHeight = $(this).outerHeight();
        tlScroll
            .to(circle, 0.3, {
                height: 0,
                ease: Power0.easeNone,
            });
        const scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.5,
            duration: elHeight,
        })
            .setTween(tlScroll)
            // .addIndicators()
            .addTo(controller);
    });
};
animateCircle();

const animateSection = () => {
    const controller = new ScrollMagic.Controller();
    $('.time-line .additional').each(function () {
        const tlScroll = new TimelineLite();
        const elHeight = $(this).outerHeight();
        tlScroll
            .fromTo(this, 1.4, {
                x: 100,
                ease: Elastic.easeOut,
                opacity: 0,
            }, {
                x: 0,
                ease: Elastic.easeOut,
                opacity: 1,
            });
        const scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.5,
            duration: 0,
        })
            .setTween(tlScroll)
            // .addIndicators()
            .addTo(controller);
    });
};
animateSection();

const animateCard = () => {
    const controller = new ScrollMagic.Controller();
    $('.card').each(function () {
        const tlScroll = new TimelineLite();
        tlScroll
            .fromTo(this, 0.5, {
                y: 50,
                ease: Power0.easeNone,
                opacity: 0,
            }, {
                y: 0,
                ease: Power0.easeNone,
                opacity: 1,
            });
        const scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.9,
            duration: '0%',
        })
            .setTween(tlScroll)
            .addTo(controller);
    });
};
animateCard();

const windowHeight = $(window).outerHeight() / 2;
$('.nav-side__list a').bind('click', function (e) {
    e.preventDefault();

    const target = $(this).attr('data-scroll');

    if ($('.cards').hasClass('is-blocked')) {
        $('html, body').stop().animate({
            scrollTop: $(`.is-blocked .${target}`).offset().top,
        }, 1600);
    } else {
        $('html, body').stop().animate({
            scrollTop: $(`.is-blocked .${target}`).offset().top - windowHeight,
        }, 1600);
    }

    return false;
});

$(window).scroll(() => {
    const scrollDistance = $(window).scrollTop();

    if ($('.cards').hasClass('is-blocked')) {
        $('.cards__block').each(function (i) {
            if ($(this).offset().top - windowHeight + -1 <= (+scrollDistance)) {
                $('.nav-side__list a').removeClass('is-active');
                const id = $(this).attr('data-year');
                $(`#${id}`).addClass('is-active');
            }
        });
    } else {
        $('.time-line__content.disable .circle').each(function (i) {
            if ($(this).offset().top - windowHeight + -1 <= (+scrollDistance)) {
                $('.nav-side__list a').removeClass('is-active');
                const id = $(this).attr('data-year');
                $(`#${id}`).addClass('is-active');
            }
        });
    }
}).scroll();

(function () {
    const controller = new ScrollMagic.Controller();
    const tl3 = new TimelineMax();
    const tl4 = new TimelineMax();
    const tl6 = new TimelineMax();
    const tl7 = new TimelineMax();
    const tl8 = new TimelineMax();
    const tl9 = new TimelineMax();
    tl7
        .staggerTo('.footer svg stop', 1, {
            cycle: {
                stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
            },
        }, 0.1, 0);
    new ScrollMagic.Scene({
        triggerElement: '.footer',
        triggerHook: 0.5,
        duration: '100%',
    })
        .setTween(tl7)
        .addTo(controller);
    tl8
        .to('.footer__img1', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
        triggerElement: '.footer',
        triggerHook: 0.3,
        duration: '100%',
    })
        .setTween(tl8)
        .addTo(controller);
    tl9
        .to('.footer__img3', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
        triggerElement: '.footer__img2',
        triggerHook: 0.5,
        // offset: '50%'
        duration: '100%',
    })
        .setTween(tl9)
        .addTo(controller);
}());
$('.burger-icon').click(function(e) {
    e.preventDefault()
    $('.js-nav').addClass('is-open');
});
$('.js-close-menu').click(function(e) {
    e.preventDefault()
    $('.js-nav').removeClass('is-open');
});
